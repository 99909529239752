// HomeBannerItem.jsx
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DecryptionWrapper from '../container/DecryptionWrapper.js';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import StatefulImage from '../component/StatefulImage.jsx';
import { color } from '../style/variables.js';

import BannerPlaceholderResource from '../../img/placeholder-banner.svg';

import { ButtonId } from '../resource/mixpanel.js';

export const HomeBannerItem = ({
  bannerLink,
  category,
  bannerData,
  isIntersecting,
  meId,
  index,
  fetchBanner,
}) => {
  useEffect(() => {
    if (isIntersecting) {
      fetchBanner({ category, url: bannerLink });
    }
  }, [isIntersecting, category, bannerLink, meId, fetchBanner]);
  if (!bannerLink || !bannerData?.src) {
    return (
      <StatefulImageWrapper>
        <PlaceholderWrapper data-key="target">
          <img
            src={BannerPlaceholderResource}
            alt="banner placeholder"
            width={300}
            height={136}
          />
        </PlaceholderWrapper>
      </StatefulImageWrapper>
    );
  }
  const replacedLink =
    bannerData.link == null
      ? ''
      : decodeURI(bannerData.link).replaceAll('{USER_ID}', meId);
  const props =
    bannerData.link == null
      ? {}
      : bannerData.reactLink
        ? { as: Link, to: replacedLink }
        : { as: 'a', href: replacedLink, target: '_blank' };

  return (
    <BannerWrapper
      {...props}
      data-element_id={
        category === 'pseudo_video_banner'
          ? ButtonId.Home.ButtonVideoBanners
          : ButtonId.Home.ButtonHomeBanners
      }
      data-tracking_payload={{
        url: bannerData.link,
        'si.url': bannerData.src,
        'banner.order': index + 1,
      }}
    >
      <StatefulImageWrapper>
        <StatefulImage>
          <PlaceholderWrapper data-key="loading">
            <img
              src={BannerPlaceholderResource}
              alt="banner placeholder"
              width={300}
              height={136}
            />
          </PlaceholderWrapper>
          <PlaceholderWrapper data-key="error">
            <img
              src={BannerPlaceholderResource}
              alt="banner placeholder"
              width={300}
              height={136}
            />
          </PlaceholderWrapper>
          <DecryptionWrapper resourceUrl={bannerData.src} data-key="target">
            <BannerImage src={bannerData.src} alt="" />
          </DecryptionWrapper>
        </StatefulImage>
      </StatefulImageWrapper>
    </BannerWrapper>
  );
};

HomeBannerItem.propTypes = {
  bannerLink: PropTypes.string,
  category: PropTypes.string,
  meId: PropTypes.string,
  bannerData: PropTypes.object,
  isIntersecting: PropTypes.bool,
  index: PropTypes.number,
  fetchBanner: PropTypes.func,
};

HomeBannerItem.defaultProps = {
  bannerLink: '',
  category: '',
  meId: '',
  bannerData: {},
  isIntersecting: false,
  index: 0,
  fetchBanner: () => null,
};

const BannerWrapper = styled.div``;

const BannerImage = styled.img`
  border-radius: 8px;
  border: solid 1px ${color.grey[700]};
  width: 300px;
  height: 136px;
`;

const PlaceholderWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: solid 1px ${color.grey[700]};
`;

const StatefulImageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export default HomeBannerItem;
